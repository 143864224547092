import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';


const offers = [
  {
    id: 1,
    title: "Oferta Destacada 1",
    discount: "30% OFF",
    backgroundColor: "#388E3C"
  },
  {
    id: 2,
    title: "Oferta Destacada 2",
    discount: "50% OFF",
    backgroundColor: "#43A047"
  },
  {
    id: 3,
    title: "Oferta Destacada 3",
    discount: "50% OFF",
    backgroundColor: "#43A047"
  }
];

const Slider = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  const changeSlide = (newIndex) => {
    if (newIndex < 0) newIndex = offers.length - 1;
    if (newIndex >= offers.length) newIndex = 0;
    setSlideIndex(newIndex);
  };

  return (
    <div 
      style={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        display: 'flex',         // Centrar en el contenedor padre
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50px',
        margin: '50px 0',
      }}
    >
      {/* Contenedor deslizable */}
      <div
        style={{
          display: 'flex',
          width: `${offers.length * 100}%`,
          height: '500px',
          borderRadius: '20px',
          transform: `translateX(-${slideIndex * 100}%)`,
          transition: 'transform 0.8s ease-in-out'
        }}
      >
        {offers.map((offer) => (
          <div
            key={offer.id}
            style={{
              flex: '0 0 100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              backgroundColor: offer.backgroundColor,
              height: '100%',
              color: '#fff'
            }}
          >
            <div 
              style={{
                maxWidth: '600px',
                display: 'flex',        // Asegura centrado
                flexDirection: 'column',
                alignItems: 'center',   // Centrar contenido dentro del slide
                justifyContent: 'center'
              }}
            >
              <h3>{offer.title}</h3>
              <p>{offer.discount}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Botón "Anterior" */}
      <button
        onClick={() => changeSlide(slideIndex - 1)}
        style={{
          position: 'absolute',
          top: '50%',
          left: '20px',
          transform: 'translateY(-50%)',
          background: '#fff',
          border: 'none',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          cursor: 'pointer'
        }}
      >
        <i className="fa-solid fa-chevron-left"></i>
      </button>

      {/* Botón "Siguiente" */}
      <button
        onClick={() => changeSlide(slideIndex + 1)}
        style={{
          position: 'absolute',
          top: '50%',
          right: '20px',
          transform: 'translateY(-50%)',
          background: '#fff',
          border: 'none',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          cursor: 'pointer'
        }}
      >
        <i className="fa-solid fa-chevron-right"></i>
      </button>

      {/* Bullets de navegación */}
      <div 
        style={{
          position: 'absolute',
          bottom: '15px',
          width: '100%',
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        {offers.map((_, idx) => (
          <div
            key={idx}
            onClick={() => setSlideIndex(idx)}
            style={{
              width: '12px',
              height: '12px',
              borderRadius: '50%',
              backgroundColor: idx === slideIndex ? '#fff' : '#888',
              margin: '0 5px',
              cursor: 'pointer'
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Slider;
